import React, {Component} from 'react';
import {Listener} from '@p24/frame-loader';
import Login, {constants} from '@p24/login_ui_component';
import classicTheme from '@p24/web-components/themes/classic';
import businessTheme from '@p24/web-components/themes/business';
import darkTheme from '@p24/web-components/themes/dark';
import {api, get} from '../../config';
import './style/index.less';

const {
    VIEW_ARE_YOU_HERE,
    VIEW_CALL_3DIGITS,
    VIEW_CAPTCHA,
    VIEW_CHANGE_PASSWORD,
    VIEW_CHANNELS,
    VIEW_EMAIL,
    VIEW_EMAIL_WAIT,
    VIEW_FACEBOOK,
    VIEW_FACEBOOK_EMAIL,
    VIEW_GPS,
    VIEW_IVR,
    VIEW_IVR_CAPTCHA,
    VIEW_LOGIN,
    VIEW_LOGIN_VALIDATION,
    VIEW_PASSWORD,
    VIEW_PIN,
    VIEW_QR_CONFIRM,
    VIEW_REGISTER,
    VIEW_SMS,
    VIEW_STANDBY,
    VIEW_SKELETON,
} = constants;

class Application extends Component {
    constructor(props) {
        super(props);
        this.height = 484;

        this.state = {
            // initiated: true,
            initiated: false,
            options: {},
        };
    }

    componentDidMount() {
        this.initializeListener();
        // Iframe height calculation
        this.countdown = setInterval(this.setContainerHeight, 100);
    }

    componentWillUnmount() {
        clearInterval(this.countdown);
    }

    getDefaultTheme = () => {
        const {colorScheme, theme = {}} = this.state.options;
        let defaultTheme = theme;

        if (colorScheme === 'classic') {
            defaultTheme = {
                ...classicTheme,
                ...theme,
            };
        }

        if (colorScheme === 'business') {
            defaultTheme = {
                ...businessTheme,
                ...theme,
            };
        }

        if (colorScheme === 'dark') {
            defaultTheme = darkTheme;
        }

        return defaultTheme;
    };

    setContainerHeight = () => {
        const currentHeight = this.container.offsetHeight;

        if (this.height !== currentHeight) {
            this.height = currentHeight;

            this.listener.style({
                height: `${this.height}px`,
                width: '100%',
                transition: 'height 200ms ease-in-out',
            });
        }
    };

    initializeListener = () => {
        this.listener = new Listener({
            onMessage: (data = {}) => {
                if (data && data.type === 'options') {
                    this.setState({
                        options: data,
                        initiated: true,
                    });
                }
            },
        });
    };

    onCancel = data => {
        this._send({
            type: 'execute',
            name: 'onCancel',
            data,
        });
    };

    onSuccess = data => {
        this._send({
            type: 'execute',
            name: 'onSuccess',
            data,
        });
    };

    onError = data => {
        this._send({
            type: 'execute',
            name: 'onError',
            data,
        });
    };

    _send(data = {}) {
        this.listener.send(data);
    }

    _containerRef = el => {
        this.container = el;
    };

    _url() {
        let search = '',
            name;
        const urlParams = this.state.options?.urlParams;

        if (urlParams) {
            const searchParams = [];

            for (name in urlParams) {
                if (urlParams.hasOwnProperty(name)) {
                    searchParams.push(`${name}=${urlParams[name]}`);
                }
            }

            search = searchParams.length ? '/?' + searchParams.join('&') : '';
        }

        return api('API_AUTH') + search;
    }

    render() {
        let content = null;
        const {options = {}, initiated} = this.state;

        if (initiated) {
            content = (
                <Login
                    {...options}
                    onCancel={this.onCancel}
                    onSuccess={this.onSuccess}
                    onError={this.onError}
                    url={this._url()}
                    hdnBase={get('HDN_BASE')}
                    // url={get('API_LOCALHOST')}
                    customization={{
                        [VIEW_ARE_YOU_HERE]: options[VIEW_ARE_YOU_HERE],
                        [VIEW_CALL_3DIGITS]: options[VIEW_CALL_3DIGITS],
                        [VIEW_CAPTCHA]: options[VIEW_CAPTCHA],
                        [VIEW_CHANGE_PASSWORD]: options[VIEW_CHANGE_PASSWORD],
                        [VIEW_CHANNELS]: options[VIEW_CHANNELS],
                        [VIEW_EMAIL]: options[VIEW_EMAIL],
                        [VIEW_EMAIL_WAIT]: options[VIEW_EMAIL_WAIT],
                        [VIEW_FACEBOOK]: options[VIEW_FACEBOOK],
                        [VIEW_FACEBOOK_EMAIL]: options[VIEW_FACEBOOK_EMAIL],
                        [VIEW_GPS]: options[VIEW_GPS],
                        [VIEW_IVR]: options[VIEW_IVR],
                        [VIEW_IVR_CAPTCHA]: options[VIEW_IVR_CAPTCHA],
                        [VIEW_LOGIN]: options[VIEW_LOGIN],
                        [VIEW_LOGIN_VALIDATION]: options[VIEW_LOGIN_VALIDATION],
                        [VIEW_PASSWORD]: options[VIEW_PASSWORD],
                        [VIEW_PIN]: options[VIEW_PIN],
                        [VIEW_QR_CONFIRM]: options[VIEW_QR_CONFIRM],
                        [VIEW_REGISTER]: options[VIEW_REGISTER],
                        [VIEW_SMS]: options[VIEW_SMS],
                        [VIEW_STANDBY]: options[VIEW_STANDBY],
                        [VIEW_SKELETON]: options[VIEW_SKELETON],
                    }}
                    theme={this.getDefaultTheme()}
                />
            );
        }

        return <div ref={this._containerRef}>{content}</div>;
    }
}

export default Application;
