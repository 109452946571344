import '@babel/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import {init} from '@sentry/browser';

import Application from './components/Application';

import {createStore, combineReducers} from 'redux';
import {Provider} from 'react-redux';

import reducers from './reducers';

const store = createStore(combineReducers(reducers));

init({
    dsn: 'https://e91bae2b74e5417fba26565b0ff73a8f@sentry.microaws.com/11',
    sampleRate: 0.1,
});

ReactDOM.render(
    <Provider store={store}>
        <Application />
    </Provider>,
    document.getElementById('app'),
);
